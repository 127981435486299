.callback-button {
    display: block;
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
    width: auto;
}

@media (max-width: 768px) {
    .callback-button {
        bottom: 40px;
    }
}

.callback-button > i {
    padding: 20px;
    color: #fff;
    background: #3f945d;
    font-size: 25px;
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(63, 148, 93, .4);
    cursor: pointer;
}

.callback-button > .phone-icon {
    animation: 10s infinite cb-change-icon-em, 2s infinite pulse;
}

.callback-button > .phone-icon span {
    animation: 10s infinite cb-change-icon-em;
}

.callback-button > .comment-icon,
.callback-button > .comment-icon span {
    animation: 10s 5s infinite cb-change-icon-em;
}

.callback-button > .comment-icon{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.callback-button > .times-icon{
    z-index: 2;
    transition: 150ms;
}
.callback-button > .times-icon.open{
    background: var(--button-hover-color);
    box-shadow: inset 0 0 7px rgba(0,0,0,.25);
}

.callback-button > .times-icon.open:hover{
    -webkit-filter: brightness(98%);
    box-shadow: inset 0 0 12px rgba(0,0,0,.25);
}

.callback-button-embed {
    z-index: 100000
}

.callback-button-embed i {
    display: block;
}

.callback-button-embed i span {
    display: block;
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
}

.callback-button-embed .select-item i {
    display: inline;
}

.callback-button-embed .select-item i span {
    display: inline-block;
    width: 25px;
    height: 16px;
    transform: translateY(2px);
}

.callback-button-embed i.phone-icon span {
    background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="white" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>');
}

.callback-button-embed i.comment-icon span {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="white" d="M256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160-93.3 160-208 160z"/></svg>');
}

.callback-button-embed i.times-icon span {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="white" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>');
}

.no-animation {
    animation: unset;
}

@keyframes cb-change-icon-em {
    0%, 100%, 50% {
        -webkit-background-size: 0;
        background-size: 0;
        z-index: -1
    }

    3%, 47% {
        -webkit-background-size: 25px;
        background-size: 25px;
        z-index: 1
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(63, 148, 93, .4)
    }

    70% {
        box-shadow: 0 0 0 15px rgba(63, 148, 93, 0)
    }

    100% {
        box-shadow: 0 0 0 0 rgba(63, 148, 93, 0)
    }
}