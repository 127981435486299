*, *::before, *::after {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100%;
    height: auto;
    font-family: Arial, sans-serif;
    overflow-x: hidden;
}

#root, .App, main{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

footer {
    padding: 20px 0;
    text-align: center;
}

:root {
    --background-light: #F5F5F5;
    --background-dark: #121212;
    --text-color-light: #000;
    --text-color-dark: #fff;
    --button-hover-color: #df5500;
    /*--button-hover-color: #ffa500;*/
}

.light, .light-theme {
    background-color: var(--background-light);
    color: #333;
}

.dark, .dark-theme {
    background-color: var(--background-dark);
    color: #E0E0E0;
}

.active {
    color: red;
}

section {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1200px;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-self: center;
}

@media (max-width: 768px) {
    section {
        width: auto;
        max-width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
}