.contacts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0;
    width: 100%;
    padding: 0 0 10px;
}

.contact-details {
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    width: 100%;
    text-align: center;
    order: 1;
}

.contact-icon{
    justify-content: center;
    display: flex;
    margin: 0;
}

.contact-text{
    width: 100%;
}

.contact-text p a{
    text-decoration: none;
}

.light .contact-text p a:hover,
.dark .contact-text p a:hover{
    color: var(--button-hover-color);
}

.dark .contact-text p a{
    color: var(--text-color-dark);
}
.light .contact-text p a{
    color: var(--text-color-light);
}

.contact-icon img {
    max-width: 200px;
    order: 2;
    display: block;
    margin: 0;
}

.contacts-container .map-container{
    padding: 0;
    margin: 0;
}

.map-container iframe {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 300px;
    border-radius: 8px;
    overflow: hidden;
    order: 3;
}

.dark .map-container iframe{
    filter: invert(90%);
}

.dark .contact-details{
    border-color: var(--text-color-dark);
    border-style: solid;
    border-width: 2px;
}

.contact-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border: 1px solid;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    width: 100%;
    text-align: center;
}

.dark .contact-card{
    border-color: var(--text-color-dark);
}
.light .contact-card{
    border-color: #ddd;
}


/* Desktop layout adjustments */
@media (min-width: 768px) {

    .contact-details, .contact-icon {
        width: 50%;
    }
    .map-container {
        width: 100%;
        order: 2;
    }
}

/* Mobile layout adjustments */
@media (max-width: 767px) {
    .contact-icon img {
        display: none;
    }
    .contact-details, .map-container {
        width: 100%;
    }
}
