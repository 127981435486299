.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.modal-content {
    position: relative;
    padding: 20px;
    background: white;
    border-radius: 8px;
}

.modal-content img {
    max-width: 90vw;
    max-height: 80vh;
}
.modal-close-btn {
    /*display: flex;*/
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px black solid;
    /*background: none;*/
    color: var(--text-color-light);
    font-size: 24px;
    cursor: pointer;
    border-radius: 50px;
    padding: 0 7px 1px;
    text-align: center;
    justify-content: center;
    align-self: center;
    align-content: center;
    align-items: center;
    background-color: white;
}

.modal-close-btn:hover {
    color: #df5500;
    transition: none;
}

.certificate-image {
    max-width: 100%; /* Ensures the image does not exceed the modal size */
    display: block; /* Removes bottom margin/gap */
}

.certificate-image:hover {
    cursor: pointer;
}