.fuel-comparison-section {
}

.table-responsive {
    padding: 0;
    overflow-x: auto;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.fuel-comparison-section h1 {
    text-align: center;
}

.table-responsive table {
    padding: 0;
    margin: 0;
    width: 100%;
    border-collapse: collapse;
}

.table-responsive th,
.table-responsive td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
}

@media (max-width: 768px) {
    .table-responsive th,
    .table-responsive td {
        font-size: small;
    }
}

@media (max-width: 450px) {
    .table-responsive th,
    .table-responsive td {
        font-size: x-small;
    }

}