.language-switcher {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    font-size: 16px;
    margin-left: 10px;
}

.dark .language-switcher{

}
.light .language-switcher{
    background-color: var(--background-light);
}

.language-switcher select {
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: transparent;
    color: inherit;
    padding: 5px 30px 5px 10px;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-in-out;
}

.language-switcher::after {
    content: '\25BC';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: inherit;
    pointer-events: none;
}

.dark .language-switcher select {
    background-color: black;
    color: #fff;
}

/* Hover effect adjustments */
.language-switcher select:hover {
    color: rgba(223, 85, 0, 0.9);
}
