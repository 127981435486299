
[data-balloon][data-balloon-pos=left]:before {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(-90 18 18)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") 0 0 / 100% auto no-repeat;
    width: 6px;
    height: 18px;
    margin-right: 5px;
    right: 100%;
    top: 50%;
    -webkit-transform: translate(10px, -50%);
    -moz-transform: translate(10px, -50%);
    -ms-transform: translate(10px, -50%);
    transform: translate(10px, -50%);
}

[data-balloon]:before {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22#00a6d9%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") 0 0 / 100% auto no-repeat;
    width: 18px;
    height: 6px;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: .1s ease-out .1s;
    -moz-transition: .1s ease-out .1s;
    -ms-transition: all .1s ease-out .1s;
    -o-transition: .1s ease-out .1s;
    transition: .1s ease-out .1s;
    content: '';
    position: absolute;
    z-index: 10;
}

[data-balloon][data-balloon-pos=left]:after {
    margin-right: 11px;
    right: 100%;
    top: 50%;
    -webkit-transform: translate(10px, -50%);
    -moz-transform: translate(10px, -50%);
    -ms-transform: translate(10px, -50%);
    transform: translate(10px, -50%);
}

[data-balloon]:after {
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: .1s ease-out .1s;
    -moz-transition: .1s ease-out .1s;
    -ms-transition: all .1s ease-out .1s;
    -o-transition: .1s ease-out .1s;
    transition: .1s ease-out .1s;
    font-weight: 400 !important;
    font-style: normal !important;
    text-shadow: none !important;
    border-radius: 4px;
    color: #fff;
    background: #000;
    font-size: 15px;
    text-transform: none;
    content: attr(data-balloon);
    padding: 0.5em 1em;
    position: absolute;
    white-space: nowrap;
    z-index: 10;
}

[data-balloon]:hover:after, [data-balloon]:hover:before, [data-balloon][data-balloon-visible]:after, [data-balloon][data-balloon-visible]:before {
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    pointer-events: auto
}

[data-balloon][data-balloon-pos=left]:hover:after, [data-balloon][data-balloon-pos=left]:hover:before, [data-balloon][data-balloon-pos=left][data-balloon-visible]:after, [data-balloon][data-balloon-pos=left][data-balloon-visible]:before {
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%)
}