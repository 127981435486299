.burger-menu {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 1001;
}

.burger-menu span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: #df5500;
    z-index: 100005;
    margin: 5px 0;
}
.burger-menu span:first-child {
    margin-top: 2px;
}

.dark .burger-menu{
    color: var(--text-color-dark);
}

.dark .mobile-nav {
    background-color: var(--background-dark);
    color: var(--text-color-dark);
}

.light .mobile-nav {
    background-color: var(--background-light);
    color: var(--text-color-light);
}

.mobile-nav {
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: calc(100vh - 50px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10001;
    gap:40px
}

.mobile-nav.show {
    display: flex;
    transform: translateX(0);
}

@media (max-width: 768px) {
    .burger-menu {
        display: block;
    }
    .mobile-nav {
        display: flex;
    }
}
