.home-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    margin: 0 auto;
    overflow: hidden;
}

.home-section h1 {
    margin-top: 300px;
    max-width: 90%;
}

.home-logo{
    margin-top: calc(15% + 100px);
    max-height: 60%;
    max-width: 90%;
}

.light .home-section {
    background-image: url('../../images/pellets.jpg');
}

.dark .home-section {
    background-image: url('../../images/pellets_fire.jpg');
}
