header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.light header {
    background-color: var(--background-light);
}

.dark header {
    background-color: black;
}

.logo-container img {
    width: 200px;
    margin-right: 10px;
    margin-top: 2px;
    cursor: pointer;
    transition: transform 0.1s;
}
.logo-container img:hover{
    transform: scale(1.02);
}

nav {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: 10px;
}

.mobile-nav  button,
.desktop-nav button {
    border: none;
}

.mobile-nav  button,
.desktop-nav button,
.language-switcher select {
    background-color: transparent;
    color: inherit;
    padding: 5px 15px;
    cursor: pointer;
    font-size: 16px;
    transition: border-bottom-color 1s, transform 0.1s, color 0.5s;
}
.mobile-nav  button:hover,
.desktop-nav button:hover {
    color: var(--button-hover-color);
    transform: scale(1.05);
    border-bottom: 1px solid var(--button-hover-color);
}

.theme-switcher label:hover {
    background-color: var(--button-hover-color);
    cursor: pointer;
    transform: scale(1.05);
}

.theme-switcher {
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    margin-top: 5px;
}


.theme-switcher label {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 15px;
    transition: background-color 0.3s,transform 0.1s, color 0.5s;
}

.theme-switcher label::after {
    content: '';
    position: absolute;
    top: 2px;
    background-color: #fff;
    border-radius: 50%;
    left: 3px;
    width: 16px;
    height: 16px;
    transition: transform 0.25s;
}

.theme-switcher input[type="checkbox"]:checked + label::after {
    transform: translateX(19px);
    background-color: black;
}

.theme-switcher input[type="checkbox"] {
    display: none;
}

.desktop-nav {
    display: flex;
    gap: 10px;
}

.header-controls {
    display: flex;
    align-items: center;
    margin-right: auto;
}

@media (max-width: 768px) {
    .desktop-nav {
        display: none;
    }
}