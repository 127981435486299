.productPhotos-section {
    text-align: center;
}

.productPhotos-section .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 10px;
    margin-top: 20px;
}

.productPhotos-section .gallery .gallery-image {
    width: calc(100% / 4);
    height: 100%;
    cursor: pointer;
    transition: transform 0.3s ease;
    align-self: center;
}

@media (max-width: 768px) {
    .productPhotos-section .gallery-image {
        width: 40%;
    }
}

