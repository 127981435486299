.footer {
    width: 100%;
    font-size: 14px;
    line-height: 1.5;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    max-width: 1200px;
    padding-left: 20px;
    padding-right: 20px;
}

.footer-bottom{
    border-top: 2px solid;
}

.footer-logo {
    max-width: 170px;
    align-self: center;
}

.footer-links a,
.footer-contacts a {
    text-decoration: none;
    margin: 0 10px;
}

.dark .footer-links a,
.dark .footer-contacts a {
    color: var(--text-color-dark);
}

.light .footer-links a,
.light .footer-contacts a {
    color: var(--text-color-light);
}

.footer-contacts {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 15px;
    font-size: 20px;
}

.footer-contacts a {
    color: inherit;
    transition: color 0.3s ease;
}

.footer-social {
    display: flex;
}

.footer-social a {
    color: inherit;
    font-size: 20px;
    margin-bottom: 0;
}

.footer-bottom {
    margin-top: 20px;
    text-align: center;
    color: inherit;
}

.footer-bottom p {
    margin-top: 20px;
    font-size: 14px;
}
.footer-bottom a{
    transition: color 0.3s ease;
    text-decoration: none;
}

.dark .footer-bottom a{
    color: var(--text-color-dark);
}

.light .footer-bottom a{
    color: var(--text-color-light);
}

.footer-nav{
    display: flex;
    flex-direction: row;
    height: 100%;
    flex-grow: 1;
}

.subnav{
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.footer-nav button {
    background-color: transparent;
    border: none;
    color: inherit;
    padding: 0 10px;
    cursor: pointer;
    transition: color 0.3s ease;
    text-decoration: none;
    font-size: 20px;
}
.footer-bottom a:hover,
.footer-nav button:hover,
.footer-contacts a:hover{
    color: var(--button-hover-color);
}

.footer-bottom p{
    margin: 5px 0 0;
}

.footer{
    background-color: rgb(51, 51, 51);
    padding-bottom: 5px;
}
.footer-contacts-container{
    padding: 0;
}

.footer-contacts-container,
.nav-container{
    height: 100%;
    display: flex;
    gap:0;
    flex-direction: column;
}

.nav-container{
    flex-grow: 1;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.footer-category{
    text-transform: uppercase;
    font-size: 20px;
    margin-top: 0;
}

@media (max-width: 768px) {
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: calc(100vh - 100px);
        min-height: calc(100vh - 100px);
        padding: 20px 0;
    }

    .footer-content {
        height: 100%;
        flex-direction: column;
        align-items: center;
        flex: 1;
    }

    .footer-logo {
        max-width: 200px;
        margin-bottom: 0;
    }

    .footer-category{
        margin-bottom: 10px;
    }

    .footer-nav {
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
    }

    .footer-contacts-container{

        flex-grow: 1;
        max-height: 150px;
        justify-content: space-between;
    }

    .subnav{
        max-height: 100px;
        flex-grow: 1;
        justify-content: space-between;
        gap:5px;
    }

    .footer-contacts{
        max-height: 300px;
        flex-grow: 1;
        gap:0;
        line-height:1;
    }

    .footer-links a,
    .footer-contacts a {
        margin: 5px;
    }

    .footer-social {
        display: flex;
        gap: 0;
        margin-top: 5px;
    }

    .footer-social a {
        font-size: 30px;
    }
    .footer-bottom {
        margin-top: 10px;
    }
}